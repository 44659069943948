import React from "react";
import { Link } from "react-router-dom";
import { FaInstagram, FaTiktok, FaFacebook } from "react-icons/fa";

const Visit = () => {
  return (
    <div className="w-screen h-screen bg-[#FFFAF0] pt-20 md:pt-28">
      <div className="text-center text-[#f25151] py-5 text-5xl md:text-9xl font-bold font-rubik">
        HOURS AND LOCATION
      </div>
      <div className="flex flex-col items-center justify-center">
        <ul className="text-center text-2xl md:text-3xl text-[#f25151] font-bold font-rubik space-y-4">
          <li>
            <h1>MONDAY - WEDNESDAY</h1>
            <h2>11:00 AM - 9:00 PM</h2>
          </li>
          <li>
            <h1>THURSDAY - SATURDAY</h1>
            <h2>11:00 AM - 10:00 PM</h2>
          </li>
          <li>
            <h1>SUNDAY</h1>
            <h2>11:00 AM - 8:00 PM</h2>
          </li>
          <li>
            <h1>1923 E 7th St Ste 150 Austin, TX 78702</h1>
            <Link to="https://www.google.com/maps/place/Terrazzo+Tea/@30.2618644,-97.7258298,17z/data=!3m1!4b1!4m6!3m5!1s0x8644b5334d8e0c77:0x8b983c23219325d0!8m2!3d30.2618645!4d-97.7209589!16s%2Fg%2F11sjhz8dqs?entry=ttu">
              <button className="text-[#f25151] group border-2 border-[#f25151] rounded-lg px-4 py-2 my-2 hover:bg-red-600 hover:border-red-600 hover:text-white text-sm">
                GET DIRECTIONS
              </button>
            </Link>
          </li>
          <li>
            <a href="tel:512-363-5630">
              <h1>512-363-5630</h1>
            </a>
          </li>
          <li>
            <h2>
              <a href="mailto:TERRAZZO.TEA@GMAIL.COM">TERRAZZO.TEA@GMAIL.COM</a>
            </h2>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Visit;
