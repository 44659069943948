import { Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Menu from "./components/Menu";
import Catering from "./components/Catering";
import Visit from "./components/Visit";
import Merch from "./components/Merch";
import Footer from "./components/Footer";


function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/catering" element={<Catering />} />
        <Route path="/visit" element={<Visit />} />
        <Route path="/merch" element={<Merch />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;