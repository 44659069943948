import React, { useState } from "react";
import {
  FaBars,
  FaTimes,
  FaInstagram,
  FaTiktok,
  FaFacebook,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";

export default function NavBar() {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  return (
    <>
      <div className="fixed w-full h-20 flex justify-between items-center px-4 text-[#f25151] text-xl z-[100] bg-[#FFFAF0]">
        <div className="flex items-center justify-start">
          <Link to="/">
            <img src={Logo} alt="Logo img" style={{ width: "225px" }} />
          </Link>

          <ul className="hidden md:flex space-x-4 font-bold font-rubik ml-4">
            <li>
              <Link to="/about">ABOUT</Link>
            </li>
            <li>
              <Link to="/menu">MENU</Link>
            </li>
            <li>
              <Link to="/catering">CATERING</Link>
            </li>
            {/* <li>
              <Link to="/merch">MERCH</Link>
            </li> */}
            <li>
              <Link to="/visit">VISIT</Link>
            </li>
          </ul>
        </div>

        <ul className="hidden md:flex space-x-4 font-bold font-rubik text-3xl items-center">
          <li>
            <Link to="https://www.instagram.com/terrazzo.tea/">
              <FaInstagram />
            </Link>
          </li>
          <li>
            <Link to="https://www.tiktok.com/@terrazzo.tea?fbclid=IwAR2cbKUM6WeuPUabFbwsJTU1KaP19OZFI4O0i8MwTEOIhq0-ttj61FfVAF8">
              <FaTiktok />
            </Link>
          </li>
          <li>
            <Link to="https://www.facebook.com/profile.php?id=100093137031094">
              <FaFacebook />
            </Link>
          </li>
          <li>
            <Link to="https://terrazzo-tea.square.site/">
              <button className="text-[#f25151] group border-2 border-[#f25151] rounded-lg px-4 py-2 my-2 flex items-center hover:bg-red-600 hover:border-red-600 hover:text-white text-sm">
                ORDER
              </button>
            </Link>
          </li>
        </ul>

        <div onClick={handleClick} className="md:hidden z-10">
          {!nav ? <FaBars /> : <FaTimes />}
        </div>

        <ul
          className={
            !nav
              ? "hidden"
              : "absolute top-0 left-0 w-full h-screen flex flex-col font-bold font-rubik justify-center items-center bg-[#FFFAF0]"
          }
        >
          <li className="py-6 text-4xl">
            <Link onClick={handleClick} to="/about">ABOUT</Link>
          </li>
          <li className="py-6 text-4xl">
            <Link onClick={handleClick} to="/menu">MENU</Link>
          </li>
          <li className="py-6 text-4xl">
            <Link onClick={handleClick} to="/catering">CATERING</Link>
          </li>
          {/* <li className="py-6 text-4xl">
            <Link onClick={handleClick} to="/merch">MERCH</Link>
          </li> */}
          <li className="py-6 text-4xl">
            <Link onClick={handleClick} to="/visit">VISIT</Link>
          </li>
          <li>
            <Link to="https://terrazzo-tea.square.site/?owg-fulfillment=pickup&location=11ee1056e8b1d60e8539ac1f6bbba828&rwg_token=ADQ7psRtb2TJe3Jzqn__xqCA2rdCGTxnJ-6YwxcRjo9FLe4Lw223MnMVeEpXObtQYCKQjVBRdtxHkhOZ3LU2lGog9MMYG631Vg%3D%3D">
              <button className="text-[#f25151] group border-2 border-[#f25151] rounded-lg px-4 py-2 my-2 flex items-center hover:bg-red-600 hover:border-red-600 hover:text-white text-sm">
                ORDER
              </button>
            </Link>
          </li>
          <li className="flex pt-7">
            <Link
              to="https://www.instagram.com/terrazzo.tea/"
              className="mx-1 text-3xl"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.tiktok.com/@terrazzo.tea?fbclid=IwAR2cbKUM6WeuPUabFbwsJTU1KaP19OZFI4O0i8MwTEOIhq0-ttj61FfVAF8"
              className="mx-1 text-3xl"
            >
              <FaTiktok />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=100093137031094"
              className="mx-1 text-3xl"
            >
              <FaFacebook />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
