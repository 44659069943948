import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/logo.png";
import {
    FaBars,
    FaTimes,
    FaInstagram,
    FaTiktok,
    FaFacebook,
  } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="bg-[#FFFAF0]">
      <ul className="flex justify-center font-bold text-3xl text-[#f25151] pt-3">
      <li className="pb-5">
            <Link to="https://www.instagram.com/terrazzo.tea/">
              <FaInstagram />
            </Link>
          </li>
          <li className="px-5">
            <Link to="https://www.tiktok.com/@terrazzo.tea?fbclid=IwAR2cbKUM6WeuPUabFbwsJTU1KaP19OZFI4O0i8MwTEOIhq0-ttj61FfVAF8">
              <FaTiktok />
            </Link>
          </li>
          <li>
            <Link to="https://www.facebook.com/profile.php?id=100093137031094">
              <FaFacebook />
            </Link>
          </li>
      </ul>
    </div>
  );
};

export default Footer;
