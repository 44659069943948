import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";

const Merch = () => {
  const slides = [
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaW2YCju-7qNpQ5BaBM1moivCRnkZgtSwYKpHy7Fuzz03-WSHOlZGwjyVMVQAdz7GlNyvV0RaKWgiIDq3fPiROxUmzPcusQ4EigU22GFhv7GvIDTfUU6RtTYXf03OZL41HoUWtJMan2oBVwrHsntZk7rZQ=w1434-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaXTjWCwPpsmM9Wr2taBzXF7e-V5nZ2TSAGZT9QFOfK6E2Wot0-lJDVE1yCkgV6u44kFmrn7rs_JlS8XYCL1K3Fv6wU4xLj6EiEZQNBHxQ8v3eQn1Wgc-_9XfEWx07IV0ur9HHmj8oOwjqhR-JnZean9pA=w1434-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaXuMwdRUwUmPcdSjUdTckKzkJVOIs6R6nlwUhFeDVvjaV9KbK96ezbjuqeXg1f0qx8guqXCTkWmvE7hgTOtAXepcJcp8svaKbAmVc241_W-PHMglyq3iGoilNJbigrXPk8Fh5j2FlprYZIlykdCub4CUw=w1384-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaW4uA-qomF7Nasyj9wOvpU_t-yRO8L6EYE8x3aI843bKJeIJbp7jKNTx6N7GB2c5uDZ0dJeh3eUlcD44zzeKfN90pqcMxvDRZcFr7s9YQnjN5jJk7_YbicoW2srk7SLrzajTwadJxTM5iUUk-GGaVmrAg=w1434-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaVQzu-a2j0QQFyPawTdl9mmuxayGn5hVool43qs0U9heR_yah5oTabth4ApElUsXkoDBjbcyQo2C8BMcCJlViOXLtcXpFOhdbyEB5MCZBkX8YT7fApP-Gu2jBcWFO4-xUmfoQdf5v6C6o_qklOYKS85Pw=w1194-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaXA-W1EoImBQzE3oKkNizLPUoWv6kjomJIqtsxt_sOqO6_y36zH_TURBLptiCLhr-mgfGGk5OrK-77uVAybuHWuIKjWN9rBwpBVdFVeafeUlGaCVaFvCa37hmKlCxYUGSNoT_DOgO_Jw_nZ7r3Q9NjlyA=w1384-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaUvFO-F9QSQylXT3FXVHlKrx2Tbukn2onowVTzl7LR8jv1lWja_Vkw2AE-2rGaoCHVJFTlxTCdfEddpQ5hOS3mUF5Ewiots37nY53nzXIhCylHhiJTwOz7ZTJuqPWbENo7_i-dTZkldn93MDBZf-iHBIA=w1194-h1792-s-no?authuser=0",
    },
  ];
  const slide2 = [
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaV6JvGY10tRbOfzs_zMQThphOPNaFGQAjtUaEuQogA4YkRHjMXm0IftzTfUnJM8nF1_TQgV-aDDAu48DVHPAshWbYbcoqrSkzyO0AL62eM0m9cz-3wJ_pFKOFfbFZdCZaLBOsgVw2DFoGRxuRMDflDS9A=w1384-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaVmVJ7-YPnC_eDH6s23Y2BGXknUPsr-lrVWU9KG6UFpf4EZoaXjpuSiB-ZO6So39vZspK8YdQfvFvXm1GfQBU6TrfiJdM5a34JCdPLBQzsmEF2bURqk7mMxFHbjCfyypfdVTbZYGgSqXRrrqYwpyHdbzQ=w1194-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaW_TgcdYsbbh5KjOsrW7ESSU9XnF6gmh-zeu9I4fV54omEyIFNGrJXzVTxN_qK6WHQI8duTMhc4KHW_qST62ahUe6sNJXlRGtDvoPhAKYeCRVBr9n7FIp-i0vJt_Ew3Ydd5z0DVEcMWDeGpxEJXpGZleA=w1194-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaWZyQj_6EXruCa0gF5vMjOi3W1awiO7c2CP_RANxfSWnf_Cy0MAu_rBd-xu8SnZMEgGQCoBWUrwxoWSXzK8smT1_ZmxJPXjPUHB3U158layAwP5CU5wcv9c_xabLKkg8h4qOrdTvMp6VT0MOQBy8QHYtg=w1434-h1792-s-no?authuser=0",
    },
    {
      url: "https://lh3.googleusercontent.com/pw/AJFCJaWepkTzThqf1cifEr2wsDwiaV0rXH2Eo-bAFT2kaDgmfGRB0qVwaMWDFPSDl-hQCqWS6iviTp8K-pmpgvS1I4tpdH0Nzrp6W_Wwx-Sbd4OC-V4cnjfjd3Frt0O4M3h6FQ7cBLqlMwlwj8pVrhrT_QilSw=w1434-h1792-s-no?authuser=0",
    },
  ];

  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);

  const prevSlide1 = () => {
    const isFirstSlide = currentIndex1 === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex1 - 1;
    setCurrentIndex1(newIndex);
  };

  const nextSlide1 = () => {
    const isLastSlide = currentIndex1 === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex1 + 1;
    setCurrentIndex1(newIndex);
  };

  const goToSlide1 = (slideIndex) => {
    setCurrentIndex1(slideIndex);
  };

  const prevSlide2 = () => {
    const isFirstSlide = currentIndex2 === 0;
    const newIndex = isFirstSlide ? slide2.length - 1 : currentIndex2 - 1;
    setCurrentIndex2(newIndex);
  };

  const nextSlide2 = () => {
    const isLastSlide = currentIndex2 === slide2.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex2 + 1;
    setCurrentIndex2(newIndex);
  };

  const goToSlide2 = (slideIndex) => {
    setCurrentIndex2(slideIndex);
  };

  return (
    <section className="w-full h-screen bg-[#FFFAF0] pt-28 flex justify-center flex-col md:flex-row ">
          {/* First Slider */}
          <div className="max-w-[1400px] h-[780px] w-full pb-5 px-4 relative group">
            <div
              style={{ backgroundImage: `url(${slides[currentIndex1].url})` }}
              className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
            ></div>
            {/* Left Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide1} size={30} />
            </div>
            {/* Right Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide1} size={30} />
            </div>
            <div className="flex top-4 justify-center py-2">
              {slides.map((slide, slideIndex) => (
                <div
                  key={slideIndex}
                  onClick={() => goToSlide1(slideIndex)}
                  className="text-2xl cursor-pointer"
                ></div>
              ))}
            </div>
          </div>
          {/* Second Slider */}
          <div className="max-w-[1400px] h-[780px] w-full pb-5 px-4 relative group ">
            <div
              style={{ backgroundImage: `url(${slide2[currentIndex2].url})` }}
              className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
            ></div>
            {/* Left Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactLeft onClick={prevSlide2} size={30} />
            </div>
            {/* Right Arrow */}
            <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
              <BsChevronCompactRight onClick={nextSlide2} size={30} />
            </div>
            <div className="flex top-4 justify-center py-2">
              {slide2.map((slide, slideIndex) => (
                <div
                  key={slideIndex}
                  onClick={() => goToSlide2(slideIndex)}
                  className="text-2xl cursor-pointer"
                ></div>
              ))}
            </div>
          </div>
    </section>
  );
};

export default Merch;
