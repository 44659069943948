import React from "react";
import two from "../assets/two.png";
import three from "../assets/three.png";
import { Link } from "react-router-dom";
import homebg from "../assets/homebg.png";
import foudnerstwo from "../assets/founderstwo.png";
import LogoWhite from "../assets/LogoWhite.png";

const Home = () => {
  return (
    <>
      <section className="w-full h-max bg-[#FFFAF0]">
        <div className="flex flex-col items-center pt-28">
          <ul className="flex flex-col md:flex-row">
            <li className="text-center text-[#f25151] px-5">
              <Link to="https://terrazzo-tea.square.site/">
                <button className="text-[#f25151] group border-4 border-[#f25151] rounded-lg p-6 my-2 text-7xl font-bold font-rubik underline hover:bg-red-600 hover:border-red-600 hover:text-white">
                  ORDER
                </button>
              </Link>
              <img src={two} className="twodrinks" alt="Image" />
            </li>

            <li className="text-center text-[#f25151] px-5">
              <img src={three} className="threerinks" alt="Image" />
              <Link to="/visit">
                <h1 className="text-8xl font-bold font-rubik underline pt-4 ">VISIT</h1>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="w-full h-max bg-[#FFFAF0]">
        <div className="flex flex-col items-center pt-6">
          <ul className="flex flex-col md:flex-row">
            <li>
              <img src={foudnerstwo} className="founderstwo" />
            </li>
            <li className="relative">
              <img src={homebg} className="homebg" />
              <div className="absolute text-center top-5 px-7 text-white p-4">
                <img src={LogoWhite} />
                <h2 className="text-2xl md:text-5xl font-rubik pt-8 md:pt-16">
                  We believe that a great cup of tea is more
                  than just a drink - it's an opportunity to connect with others
                  and build community.
                </h2>
                <Link to="/about">
                <h2 className="pt-8 underline font-rubik">Learn More</h2>
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Home;
