import React from "react";
import Group from "../assets/Group.jpg";
import homebg from "../assets/homebg.png";
import Founders from "../assets/founders.jpeg";
import terrazzopattern from "../assets/terrazzopattern.png";

const About = () => {
  return (
    <>
      <section className="w-full h-max bg-[#FFFAF0]">
        <div className="flex flex-col pt-28 items-center">
          <ul className="flex flex-col md:flex-row">
            <li className="relative">
              <img src={homebg} className="mission" />
              <div className="absolute text-center top-5 px-7 text-white font-rubik p-4">
                <h1 className="text-4xl pb-2">Mission</h1>
                <h2>
                  At Terrazzo, our mission is to create an inclusive and
                  harmonious space where individuals from all cultures,
                  backgrounds, and perspectives can converge and connect. Just
                  like the diverse elements that form a beautiful terrazzo
                  pattern, we are dedicated to unifying these unique attributes
                  to build a cohesive and welcoming community.
                </h2>
              </div>
            </li>
            <li>
              <img src={Group} className="grouppic" />
            </li>
          </ul>
        </div>
      </section>
      <section className="w-full h-max bg-[#FFFAF0]">
        <div className="relative">
          {/* <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <img src={terrazzopattern} className="terrazzo bg-center" />
          </div> */}
          <div className="flex flex-col items-center pt-16">
            <ul className="flex flex-col md:flex-row relative">
              <li>
                <img src={Founders} className="founders" />
              </li>
              <li className="relative">
                <img src={homebg} className="about" />
                <div className="absolute text-center top-5 px-7 text-white font-rubik p-4">
                  <h1 className="text-4xl pb-5">About</h1>
                  <h2>
                    Longtime friends Kimmie and Vi grew up hanging out at the
                    many boba shops in San Jose, California, which were often
                    open late. When Kimmie moved to Austin in 2017, the pair
                    “FaceTimed every single day” while Vi stayed in San Jose and
                    worked in marketing. While studying at Texas State
                    University in 2018, Kimmie opened her first business: Icy
                    Rolls, a bespoke rolled ice cream and boba shop. After being
                    laid off in San Jose, moving to Austin during the pandemic,
                    and being laid off again from her tech job, Vi said the
                    stars aligned to partner with her best friend and open their
                    own take on boba.
                  </h2>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
