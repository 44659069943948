import React from "react";

const Catering = () => {
  return (
    <div className=" bg-[#FFFAF0] min-h-screen flex flex-col justify-center items-center px-5  sm:px-6 lg:px-8">
      <div className="text-center text-[#f25151]">
        <h1 className="text-5xl sm:text-9xl font-bold font-rubik">
          INTERESTED IN HAVING TERRAZZO TEA AT YOUR EVENT?
        </h1>
        <h2 className="sm:text-xl font-bold font-rubik py-6">
          EMAIL{" "}
          <a href="mailto:TERRAZZO.TEA@GMAIL.COM" >
            TERRAZZO.TEA@GMAIL.COM{" "}
          </a>
        </h2>
      </div>
    </div>
  );
};

export default Catering;
