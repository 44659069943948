import React from "react";
import Menu1 from "../assets/Menu1.png";
import Menu2 from "../assets/Menu2.png";
import terrazzopattern from "../assets/terrazzopattern.png";

const Menu = () => {
  return (
    <div
      className="w-full h-full bg-terrazzopattern flex flex-col items-center pt-20"
      style={{
        backgroundImage: `url(${terrazzopattern})`,
      }}
    >
      <div className="flex flex-col md:flex-row w-11/12">
        <img
          src={Menu1}
          className="w-full md:w-1/2 relative z-1 "
          alt="Menu 1"
        />
        <img
          src={Menu2}
          className="w-full md:w-1/2 relative z-1  "
          alt="Menu 2"
        />
      </div>
    </div>
  );
};

export default Menu;
